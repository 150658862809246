import axios from 'axios';
import api from './api';

const requestMap = api;
export const actions = {
    FETCH_REQUEST: 'FETCH_REQUEST',
    FETCH_REQUEST_SUCCESS: 'FETCH_REQUEST_SUCCESS',
    FETCH_REQUEST_ERROR: 'FETCH_REQUEST_ERROR',
    SET_DATA: 'SET_DATA'
};

axios.interceptors.request.use(function (config) {
    config.headers['Authorization'] = 'bearer ' + localStorage.getItem('accessToken');
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

const accessToken = localStorage.getItem("accessToken")

axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error && error.response && error.response.status === 401 && accessToken) {
        window.location.href = '/page-not-found';
      } else {
        return Promise.reject(error);
      }
    }
  );


export const setStoreData = (key, value) => {
    return {
        type: actions.SET_DATA,
        key,
        value
    };
};

export const fetchDataRequest = (key) => {
    return {
        type: actions.FETCH_REQUEST,
        key
    };
};

export const fetchDataRequestError = (key, error) => {
    return {
        type: actions.FETCH_REQUEST_ERROR,
        key,
        error
    };
};


export const fetchResponseSuccess = (key, data) => {
    return {
        type: actions.FETCH_REQUEST_SUCCESS,
        key,
        data
    };
};
export const makeRequest = (
    key, path = [], params = {}, pathParam
) => {
    const request = Object.assign({}, requestMap[key]);
    if (path.length > 0) {
        request.url += '/' + path.join('/');
    }

    if (request.method === undefined || request.method == 'GET') {
        request.method = 'GET';
        request.params = params
    }
    else {
        request.data = params
    }

    if (pathParam) {
        Object.keys(pathParam).forEach((param) => {
            request.url = request.url.replace(`{${param}}`, pathParam[param])
        })
    }


    return axios(request)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
};

export const fireRequest = (
    rKey, path = [], params = {}, pathParam
) => {
    
    return (dispatch) => {
        const request = Object.assign({}, requestMap[rKey]);
        const key  =  request.storeKey ? request.storeKey: rKey;
        if (path.length > 0) {
            request.url += '/' + path.join('/');
        }

        if (request.method === undefined || request.method == 'GET') {
            request.method = 'GET';
            request.params = params
        }
        else {
            request.data = params
        }

        if (pathParam) {
            Object.keys(pathParam).forEach((param) => {
                request.url = request.url.replace(`{${param}}`, pathParam[param])
            })
        }

        
        dispatch(fetchDataRequest(key));
        
        return axios(request)
            .then((response) => {
                dispatch(fetchResponseSuccess(key, response.data));
                return response;
            })
            .catch((error) => {
                dispatch(fetchDataRequestError(key, error));
                return error.response;
            });
    };
};

//Project Module
export const createNewProject = (form) => {
    return fireRequest('createNewProject',  [], form);
};

export const getAllProject = (client_id, paginate) => {
    return fireRequest('getAllProject',  [client_id], paginate);
};

export const getAllProjectsList = (params) => {
    return fireRequest('getAllProjectsList',  [], params);
};

export const getProjectsFromFacility = (client_id, facilityId) => {
    return fireRequest('getProjectsFromFacility',  [client_id, facilityId] );
};

export const getProjectById = (client_id, project_id) => {
    return fireRequest('getProjectById',  [client_id, project_id] );
};

export const updateProject = (form, id) => {
    return fireRequest('updateProject',  [id], form);
};

export const deleteProject = (client_id, id) => {
    return fireRequest('deleteProject',  [client_id, id] );
};

export const getUserProjects = (client_id) => {
    return fireRequest('userProjectsList',  [client_id] );
};
 
export const createClient = (form) => {
    return fireRequest('createClient',  [], form );
};

export const getUserClientIntId = (client_id) => {
    return fireRequest('getUserClientIntId',  [client_id] );
};


export const getAllClients = (paginate) => {
    console.log('fetching all clients');
    return fireRequest('allClients', [], paginate)
}

export const getClientById = (id) => {
    return fireRequest('getClientById', [id])
}

export const getClientByUniqueId = () => {
    return fireRequest('getClientByUniqueId', [])
}

export const updateClient = (form, id) => {
    return fireRequest('updateClient', [id], form)
}

export const deleteClient = ( id,) => {
    return fireRequest('deleteClient',  [id] );
};

export const getReport = (id) => {
    return fireRequest('getReport', [id])
}

export const getSupersetDashboards = (filter) => {
    return fireRequest('supersetDashboards',  [], filter);
};
 

export const createMedia = ( form) => {
    return fireRequest('createMedia',  [], form ); 
}

export const deleteProjectImage = (client_id, pid) => {
    return fireRequest('deleteProjectImage',  [client_id, pid] ); 
}

export const saveConsolidateReport = (form) => {
    return fireRequest('saveConsolidateReport',  [], form ); 
}

export const updateConsolidateReport = (reportId, form) => {
    return fireRequest('updateConsolidateReport',  [reportId], form ); 
}

export const viewGeneratedReport = (client_id, pid, params) => {
    return fireRequest('viewGeneratedReport',  [client_id, pid], params); 
}
export const deleteGeneratedReport = (client_id, id) => {
    return fireRequest('deleteGeneratedReport',  [client_id, id] ); 
}
//Meter Module
export const createNewMeter = (form) => {
    return fireRequest('createNewMeter',  [], form);
};

export const getAllMeter = (client_id, paginate) => {
    return fireRequest('getAllMeter',  [client_id], paginate);
};

export const getMeterById = (client_id, meter_id) => {
    return fireRequest('getMeterById',  [client_id, meter_id] );
};

export const getMeterByMeterId = (meter_id, pid) => {
    return fireRequest('getMeterByMeterId',  [meter_id, pid] );
};

export const updateMeter = (form, id) => {
    return fireRequest('updateMeter',  [id], form);
};

export const deleteMeter = (client_id, id) => {
    return fireRequest('deleteMeter',  [client_id, id] ); 
};
export const getOnlineMonitoringList = ( mid, form) => {
    return fireRequest('getOnlineMonitoringList',  [mid], form );
};
export const downloadOnlineMtrngList = ( mid, form) => {
    return fireRequest('downloadOnlineMtrngList',  [mid], form );
};
export const getOnlineMonitoringDetails = ( id) => {
    return fireRequest('getOnlineMonitoringDetails',  [id] );
};
export const updateOnlineMonitoringDetails = ( form, itemId) => {
    return fireRequest('updateOnlineMonitoringDetails',  [itemId], form );
};
export const deleteOnlineMonitoring = (client_id, itemId) => {
    return fireRequest('deleteOnlineMonitoring', [client_id, itemId])
}
export const getWeeklySummaryTabs = (client_id, pid) => {
    return fireRequest('getWeeklySummaryTabs',  [client_id, pid] );
};
export const getMidsByFacilityId = (client_id, facilityId) => {
    return fireRequest('getMidsByFacilityId',  [client_id, facilityId] );
};
export const getProjectMeters = (client_id, params) => {
    return fireRequest('getProjectMeters',  [client_id], params );
};

//Facility Module
export const createNewFacility = (form) => {
    return fireRequest('createNewFacility',  [], form);
};

export const getAllFacilities = (client_id, paginate) => {
    return fireRequest('allFacilities',  [client_id], paginate);
};

export const getFacilityById = ( client_id, facility_id,) => {
    return fireRequest('getFacilityById',  [client_id, facility_id] );
};

export const updateFacility = (form, id) => {
    return fireRequest('updateFacility',  [id], form);
};

export const deleteFacility = (client_id, id) => {
    return fireRequest('deleteFacility',  [client_id, id] ); 
};
export const createLocation = (form) => {
    return fireRequest('createLocation',  [], form );
};

export const getClientLocations = (id, paginate) => {
    return fireRequest('getClientLocations', [id], paginate)
}

export const getClientLocationById = (client_id, id) => {
    return fireRequest('getClientLocationById', [client_id, id])
}

export const updateLocation = (form, id) => {
    return fireRequest('updateLocation', [id], form)
}

export const deleteClientLocation = (client_id,id) => {
    return fireRequest('deleteClientLocation', [client_id,id])
} 

export const createInvite =(form) => {
    return fireRequest('createInvite', [], form);
}

export const getAllInvites = (id) => {
    return fireRequest('getAllInvites', [id])
}

export const getAllInvite = (paginate) => {
    return fireRequest('getAllInvite', [], paginate)
}

export const getJoinedGreenUsers = (paginate) => {
    return fireRequest('getJoinedGreenUsers', [], paginate)
}

export const getAllClientUsers = (paginate) => {
    return fireRequest('getAllClientUsers', [], paginate)
}

export const deleteInvite = (id) => {
    return fireRequest('deleteInvite', [id] )
}

export const deactivateAuthUser = (form) => {
    return fireRequest('deactivateAuthUser', [], form )
}

export const activateAuthUser = (form) => {
    return fireRequest('activateAuthUser', [], form )
}

export const updateInvite =(id, form) => {
    return fireRequest('updateInvite', [id], form);
}

export const updateJoinedUser =(id, form) => {
    return fireRequest('updateJoinedUser', [id], form);
}

export const getUserInviteData = (id, paginate) => {
    return fireRequest('getUserInviteData', [id], paginate)
}

export const getJoinedUserData = (id, paginate) => {
    return fireRequest('getJoinedUserData', [id], paginate)
}

export const getUserInviteById = (id) => {
    return fireRequest('getUserInviteById', [id])
} 

export const getJoinedUserById = (client_id, id) => {
    return fireRequest('getJoinedUserById', [client_id, id])
} 
//Auth
export const postRegister = (form) => {
    return fireRequest('register', [], form);
};

export const postLogin = (form) => {
    return fireRequest('login', [], form);
};

export const postForgotPassword = (form) => {
    return fireRequest('forgotPassword', [], form);
};

export const tokenValidation = (token) => {
    return fireRequest('tokenValidation', [token]);
}

export const postResetPassword = (form) => {
    return fireRequest('resetPassword', [], form);
};

export const getInviteStatusByToken = (token, form) => {
    return fireRequest('getInviteStatusByToken', [token], form);
}

export const acceptInvite = (form) => {
    return fireRequest('acceptInvite', [], form);
}

export const getSpocUser = () => {
    return fireRequest('getSpocUser', [])
}

export const resendInvite = (user) => {
    return fireRequest('resendInvite', [], user)
}

export const uploadMedia = (files) => {
    return makeRequest('uploadMedia', [], files);
};

export const deleteClientMedia = (client_id,itemId,fileId) => {
    return fireRequest('deleteClientMedia', [client_id, itemId], fileId)
}

export const deleteMedia = (fileId) => {
    return fireRequest('deleteMedia', [fileId])
}

export const getInviteByToken = (token) => {
    return fireRequest('getInviteByToken', [token] )
}

// Report Module

export const getAllReports = (client_id, paginate) => {
    return fireRequest('allReports',  [client_id], paginate);
};

export const deleteReport = (client_id, id) => {
    return fireRequest('deletReport',  [client_id, id] ); 
};

export const createNewReport = (form) => {
    return fireRequest('createNewReport',  [], form);
};

export const updateReport = (form, id) => {
    return fireRequest('updateReport',  [id], form);
};

export const getReportById = (client_id, reportId) => {
    return fireRequest('getReportById',  [client_id, reportId] );
};

export const getDayWiseReport = (pid, params) => {
    return fireRequest('getDayWiseReport',  [pid], params);
};

//User Permission
export const getUsersByClientId = (client_id, facility_id) => {
    return fireRequest('getUsersByClientId',  [client_id, facility_id] );
};

//Superset
export const getGreenS3Data = (form) => {
    return fireRequest('getGreenS3Data',  [], form);
};

export const getUser = () => {
    return fireRequest('user');
}

export const getCurrentUserRole = () => {
    return fireRequest('role');
}

//Settings
export const updateProfile = (form) => {
    return fireRequest('updateProfile',  [], form);
};
export const changeUserPassword = (form) => {
    return fireRequest('changeUserPassword',  [], form);
};

//Meter Tags
export const getAllMeterTags = () => {
    return fireRequest('getAllMeterTags');
}

export const getAllMeterStatus = () => {
    return fireRequest('getAllMeterStatus');
}

export const getPrimaryContact = (client_id) => {
    return fireRequest('primaryContact', [client_id]);
}


export const getMeterCalibration = (client_id, id, form) => {
    return fireRequest('getMeterCalibration',  [client_id, id], form ); 
};

export const getMeterCalibrationById = (client_id, id) => {
    return fireRequest('getMeterCalibrationById',  [client_id, id] ); 
};

export const updateCalibrationReport = (id, form) => {
    return fireRequest('updateCalibrationReport',  [id], form ); 
};

 
// OpenAI integration
export const getOpenaiResponse = (promt, insightKey) => {
    return fireRequest('getOpenaiResponse',  [insightKey], promt );
};
export const getOpenaiResponseForImageUrl = (promt) => {
    return fireRequest('getOpenaiResponseForImageUrl',  [], promt);
};

export const getInsightKey = (insightKey) => {
    return fireRequest('getInsightKey',  [insightKey], );
};

export const deleteCalibrationReport = (client_id, pid, id) => {
    return fireRequest('deleteCalibrationReport',  [client_id, pid, id] ); 
};

export const createNewCalibrationReport = (form) => {
    return fireRequest('createNewCalibrationReport',  [], form);
};

export const cloneCalibrationReport = (client_id, id) => {
    return fireRequest('cloneCalibrationReport',  [client_id, id]);
};

export const getAllSiteVisitReport = (client_id, pid, form) => {
    return fireRequest('getAllSiteVisitReport',  [client_id, pid], form);
};

export const getSiteVisitReportById = (client_id, pid, id) => {
    return fireRequest('getSiteVisitReportById',  [client_id, pid, id]);
};

export const createNewSiteVisitReport = (form) => {
    return fireRequest('createNewSiteVisitReport',  [], form);
};

export const updateSiteVisitReport = (id, form) => {
    return fireRequest('updateSiteVisitReport',  [id], form);
};

export const deleteSiteVisitReport = (client_id, pid, id) => {
    return fireRequest('deleteSiteVisitReport',  [client_id, pid, id]);
};

export const deleteSiteVisitReportImage = (form, client_id, id) => {
    return fireRequest('deleteSiteVisitReportImage',  [client_id, id], form);
};
//Tickets
export const getAllTickets = (params) => {
    return fireRequest('getAllTickets', [], params)
}
export const getAllFacilityTickets = (facility_id) => {
    return fireRequest('getAllFacilityTickets', [facility_id])
}

export const getTicketById = (ticket_id) => {
    return fireRequest('getTicketById', [ticket_id]);
}

export const getTicketsByAssignee = (params) => {
    return fireRequest('getTicketsByAssignee', [], params)
}

export const getAllAssignees = () => {
    return fireRequest('getAllAssignees', [])
}
export const getAllFacilitiesForTickets = () => {
    return fireRequest('getAllFacilitiesForTickets', [])
}
export const getAllTicketStatus = () => {
    return fireRequest('getAllTicketStatus', [])
}
export const getAllFacilityStatus = () => {
    return fireRequest('getAllFacilityStatus', [])
}
