import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getAllProject, getCurrentUserRole } from '../../Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, CircularProgress, Card, Typography, Chip, Stack, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import SearchBox from '../Common/SearchBox';
import FilterListIcon from '@mui/icons-material/FilterList';
import { updateParams } from '../../utils';
import { setStoreData } from '../../Redux/actions';
import { Can } from '../../Context/Can'
import Pagination from '@mui/material/Pagination';
import Filter from '../Common/Filter';
import useBackActionDetection from '../../utils/UseBackActionDetection';

export default function Projects() {

    let navigate = useNavigate();
    const location = useLocation();
    const goToRoutes = (routeApi) => {
        navigate(routeApi, { replace: false });
    }

    const isBackAction = useBackActionDetection();
    const state = useSelector(st => st)
    const { getClientById: clientData } = state

    const dispatch = useDispatch();
    const [responseData, setResponseData] = useState([])
    const [plantTypeData, setPlantTypeData] = useState([])
    const [capacityData, setCapacityData] = useState([])
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [showLoader, setShowLoader] = useState(false)

    const [userAccess, setUserAccess] = useState([])
    const [isSearchOrFilterApplied, setIsSearchOrFilterApplied] = useState(false);
    const [totalPages, setTotalPages] = useState(null)
    const [totalCount, setTotalCount] = useState(0);
    const [userRole, setUserRole] = useState([])

    let tableContent = null;

    const fetchData = () => {
        setShowLoader(true)
        const params = {
            ...existingParams,
            title: existingParams?.search,
            page: isSearchOrFilterApplied ? 1 : existingParams?.page,
        }

        dispatch(getAllProject(client_id, params)).then((res) => {
            if (res && res.status === 200) {
                setResponseData(res.data.data)
                setTotalPages(res.data.totalPages)
                setTotalCount(res.data.totalItems);
                const plantTypes = res?.data?.allData?.map((item) => (item.planttype))
                const uniquePlantTypes = [...new Set(plantTypes)];
                setPlantTypeData(uniquePlantTypes);

                const capacities = res?.data?.allData?.map((item) => (item.plantcapacity))
                const uniqueCapacities = [...new Set(capacities)];
                setCapacityData(uniqueCapacities)
                setShowLoader(false)
            }
        });
        dispatch(getCurrentUserRole()).then((userResp) => {
            setUserRole(userResp?.data?.data)
        })
    };

    let { client_id: client_id } = useParams()

    useEffect(() => {
        if (existingParams?.page) {
            return
        }
        updateQueryParams({ page: 1 })
        fetchData();
    }, [queryParams]);

    useEffect(() => {
        setIsSearchOrFilterApplied(false);
        fetchData();
    }, [queryParams])

    useEffect(() => {
        if (userRole?.length > 0) {
            let arr = []
            for (const item of userRole) {
                if (item.role === "admin") {
                    item.adminAccess = {
                        edit: true,
                        delete: true,
                        id: '',
                        role: item.role
                    };
                } else if (item.permission && Array.isArray(item.permission) && item.permission.length > 0) {
                    item.adminAccess = {};
                    for (const permission of item.permission) {
                        if (permission?.permissions?.edit) {
                            item.adminAccess.edit = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''

                        }
                        if (permission?.permissions?.delete) {
                            item.adminAccess.delete = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''

                        }
                    }
                }
                arr.push(item)
            }
            if (arr?.length > 0) {
                setUserAccess(arr)
            }
        }
    }, [userRole])

    const updateQueryParams = (params) => {
        const searchParams = updateParams(existingParams, params)
        navigate({ search: searchParams.toString() }, { replace: true });
    };

    const handleSearch = (value) => {
        updateQueryParams({ search: value, page: 1 });
        setIsSearchOrFilterApplied(true);
    }

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        dispatch(setStoreData('alertMessage', {
            msg, type,
            handleClose: () => handleCloseDialog(),
        }))

    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
    }

    const handlePageChange = (event, newPage) => {
        updateQueryParams({ page: newPage });
    };

    const handleEdit = (item) => {
        const location_id = queryParams.get('location_id');
        const searchValue = queryParams.get('search');
        const facilityValue = queryParams.get('facility_id');
        const plantTypeValue = queryParams.get('plantType');
        const capacityValue = queryParams.get('capacity');
        const state = {
            status: location_id || searchValue || facilityValue || plantTypeValue || capacityValue,
        };
        localStorage.setItem('projectQueryParams', queryParams);
        navigate(`/client/${client_id}/projects/live/${item.pid}`, { state: state })
    }

    let projectList = []
    if (showLoader) {
        projectList = (
            <Grid container className='w3-margin-top' justifyContent={'center'}>
                <CircularProgress />
            </Grid>
        )
    } else if (responseData && responseData.length > 0) {
        projectList = responseData.map((item) => {
            let canEdit = false;
            let canDelete = false;
            let clPermissions = null;
            const clientPermission = clientData?.data?.data?.permission;
            if (clientPermission) {
                clPermissions = Object.keys(clientPermission).filter(key => clientPermission[key]).map(key => key.charAt(0).toUpperCase() + key.slice(1));

            }
            if (userAccess?.length > 0) {
                userAccess?.find(uItem => {
                    uItem?.permission?.find(pItem => {
                        if ((pItem.itemId === item.pid || item.location_id === pItem.itemId || item.facility_id === pItem.itemId) && clPermissions?.includes("Project")) {
                            if (pItem.permissions.edit) {
                                canEdit = true
                                canDelete = true
                            }
                            if (pItem.permissions.delete) {
                                canDelete = true
                            }
                        }
                        if (pItem.client_id === client_id && pItem.type === 'client' && pItem.permissions.edit) {
                            canEdit = true;
                            canDelete = true;
                        }
                    })
                    if (uItem.role === 'admin') {
                        canEdit = true;
                        canDelete = true;
                    }
                })
            }
            return (
                <Grid key={item.projname} item style={{ width: '100%' }}>
                    <Card style={{ cursor: 'pointer' }}
                        onClick={() => handleEdit(item)}
                        onMouseEnter={() => {
                            setHoveredRowId(item?.pid);
                        }}
                        onMouseLeave={() => {
                            setHoveredRowId(null);
                        }}
                    >
                        <Grid container className='w3-padding'>
                            <Grid container item justifyContent={'space-between'}>
                                <Grid>
                                    <h5 component="h4">{item.short_name}</h5> 
                                </Grid>
                                <Grid>
                                    <Typography style={{ wordWrap: 'break-word', marginTop: 5 }}>{item?.project_team_email}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container style={{ marginBottom: '4px' }} justifyContent={'space-between'}>
                                <Grid item className='project-name'>
                                    {item.projname}
                                </Grid>
                                <Grid item>
                                    <Stack spacing={1} direction={'row'}>
                                        <Tooltip title="Project Type">
                                        <Chip color="primary" size='small' label={item?.projecttype} />
                                        </Tooltip>
                                        <Tooltip title="Plant Type">
                                        <Chip color="secondary" size='small' label={item?.planttype} />
                                        </Tooltip>
                                        <Tooltip title="PID">
                                        <Chip color="secondary" size='small' label={item?.pid} />
                                        </Tooltip>
                                        <Tooltip title="Plant Capacity">
                                        <Chip size='small' label={item?.plantcapacity} />
                                        </Tooltip>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                                <Grid item xs={6}>
                                    <span>{item?.facilities?.facility_name}, {item?.facilities?.location?.name}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            );
        })
    } else if (responseData && responseData?.length === 0 && [...queryParams.keys()].some(key => key !== 'page')) {
        projectList = (
            <Grid container justifyContent={"center"} style={{ marginTop: '4%' }}>
                <h5 className='w3-text-grey'>  No Data Found
                </h5>
            </Grid>
        )
    } else {
        projectList = (
            <Grid container style={{ marginTop: '4%', justifyContent: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Projects are deployed for client.
                </h5>
            </Grid>
        )
    }

    tableContent = projectList

    const itemsPerPage = 20;

    return (
        <div className='w3-padding-large'>
            <Grid container>
                <Grid item md={12}>
                    <Grid item container spacing={8} paddingY={2}>
                        <Grid item md={6}>
                            <Can do="manage" on="CreateProject">
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        if (localStorage.getItem('projectQueryParams')) {
                                                localStorage.removeItem('projectQueryParams');
                                        }
                                        goToRoutes(`/client/${client_id}/projects/create`);
                                    }}
                                >
                                    Create New Project
                                </Button>
                            </Can>
                        </Grid>
                        <Grid item md={6} container justifyContent="flex-end" direction="row">
                            <Grid item container md={12} direction="row">
                                <Grid item md={11} container justifyContent="flex-end">
                                    <h6 style={{ paddingRight: '5px', fontSize: '15px' }}>Search By: </h6>
                                    <SearchBox
                                        value={queryParams.get('search') || ''}
                                        placeholder='Project Name / Short Name / PID'
                                        search={handleSearch}
                                        isBrowserBackAction={isBackAction}
                                    />
                                </Grid>
                                <Grid item container md={1} justifyContent="flex-end">
                                    <IconButton onClick={() => setShowFilterDialog(true)} title="Apply Filter">
                                        <FilterListIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Filter
                        filterArea="Projects"
                        plantTypeOption={plantTypeData}
                        capacityOption={capacityData}
                        showFilterDialog={showFilterDialog}
                        setShowFilterDialog={setShowFilterDialog}
                        updateQueryParams={updateQueryParams}
                        queryParams={queryParams}
                        setIsSearchOrFilterApplied={setIsSearchOrFilterApplied}
                    />
                    <Grid container spacing={1} paddingY={1}>
                        {tableContent}
                    </Grid>
                    {( responseData && responseData.length > 0 ) && ( 
                        <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
                                <span style={{ fontFamily: '"Helvetica Neue"', fontSize: '13px', color: '#3d3d3d' }}>
                                    {Math.min((parseInt(existingParams.page) - 1) * itemsPerPage + 1, totalCount)}-
                                    {Math.min(parseInt(existingParams.page) * itemsPerPage, totalCount)} of {totalCount}
                                </span>
                            </Grid>
                            <Pagination
                                page={parseInt(existingParams?.page) || 1}
                                count={totalPages}
                                variant="outlined"
                                shape="rounded"
                                color="primary"
                                onChange={handlePageChange}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}




