
import React, { useEffect, useState } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';

import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import { Can } from '../../Context/Can';
import AssetsDetails from '../AssetsDetails';
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import SiteVisitReportList from '../Projects/SiteVisitReportList';
import { getAllFacilities, getProjectsFromFacility } from '../../Redux/actions';
import LiveView from '../Live';
import { useDispatch, useSelector } from 'react-redux';
import WaterBalance from '../AiDashboard/SustainabilityDasboard/WaterBalance';
import Tickets from '../Tickets/Tickets';
import CalibrationList from '../Meters/CalibrationList';
import ReportsView from '../AiDashboard/ReportsView';

export default function DigitalisationCommon() {
    const dispatch = useDispatch();
    const { client_id, facilityId, tabId } = useParams();
    const navigate = useNavigate();
    const [value, setValue] = useState('live');
    const [pids, setPids] = useState([])
    const [facilities, setFacilities] = useState([]);
    const [selectedFacilityId, setSelectedFacilityId] = useState(facilityId);
    const [showLoader, setShowLoader] = useState(true);
    const [projectsFetched, setProjectsFetched] = useState(false);

    const digiTicketsQueryParams = localStorage.getItem("digiTicketsQueryParams");
    const calibrationQueryParams = localStorage.getItem("calibrationQueryParams");
    const siteVisitQueryParams = localStorage.getItem("siteVisitQueryParams");
    const urlParams = new URLSearchParams(window.location.search);

    const state = useSelector(st => st);
    const { getProjectsFromFacility: projectFacility, allFacilities } = state   

    const [showErrorMessage, setShowErrorMessage] = useState(true)

    useEffect(() => {
        setValue(tabId)
    }, [tabId]);

    useEffect(() => {
        if(pids?.length === 0 || facilities?.length === 0 ) {
            setShowErrorMessage(true)
            return
        } 
        setShowErrorMessage(false)
        
    }, [pids, facilities])

    useEffect(() => {
    setShowLoader(true)
    setSelectedFacilityId(facilityId)
    dispatch(getProjectsFromFacility( client_id, facilityId )).then((res) => {
        const response = res?.data?.data
        if ( response?.length > 0 ){
            const projectPids = response?.map((item) => item.pid)
            setPids(projectPids)
        } else {
            setPids([])
        }
        setShowLoader(false)
        setProjectsFetched(true)
        })
    },[facilityId, tabId])  

    useEffect(() => {
        setShowLoader(true)
        dispatch(getAllFacilities(client_id)).then((res) => {
            const facResponse = res?.data?.allData
            setFacilities(facResponse)
            if ( facResponse?.length > 0 && !(facResponse.find((item) => item.id === facilityId)) ) {
                const facilityIdToUse = facResponse[0].id;
                setSelectedFacilityId(facilityIdToUse);
            }
        })
    },[tabId])

    const handleTabSwitch = (event, newValue) => {
        setValue(newValue);
        navigate(`/client/${client_id}/digitalisation/${selectedFacilityId}/${newValue}`)
    };    

      const tabNames = ['live', 'summary-table', 'report-view', 'water-balance', 'tickets', 'site-visit-report', 'calibration-report'];

    let found = false;
    for (const tabName of tabNames) {
        if (window.location.pathname.includes(tabName)) {
            found = true;
            break;
        }
    }

    if (!found) {
        navigate('/page-not-found');
    }

    const handleFacilityChange = (event) => {
        setProjectsFetched(false)
        setPids([])
        if ( event.target.value !== selectedFacilityId ){
            setSelectedFacilityId(event.target.value);
        }
        //Clearing the localstorage queryparams when changing the facility for Tickets, Calibration and Site-Visit
        if( localStorage.getItem('calibrationQueryParams') || localStorage.getItem('siteVisitQueryParams') || localStorage.getItem('digiTicketsQueryParams') ) {
            localStorage.removeItem('calibrationQueryParams');
            localStorage.removeItem('siteVisitQueryParams');
            localStorage.removeItem('digiTicketsQueryParams');
        }
        return
    };

    useEffect(() => {
        // Update URL with selected facilityId
        if ( tabId === 'tickets' && digiTicketsQueryParams) { //Retain the digiTicketsQueryParams when navigating back from ticket details page
            navigate(`/client/${client_id}/digitalisation/${selectedFacilityId}/tickets?${digiTicketsQueryParams?.toString()}`, { replace: true })
        }
        else if( tabId === 'calibration-report' && calibrationQueryParams) { //Retain the calibrationQueryParams when navigating back from Calibration edit and View page
            navigate(`/client/${client_id}/digitalisation/${selectedFacilityId}/calibration-report?${calibrationQueryParams?.toString()}`, { replace: true })
        }else if( tabId === 'site-visit-report' && siteVisitQueryParams) { //Retain the siteVisitQueryParams when navigating back from Site-Visit edit and View page
            navigate(`/client/${client_id}/digitalisation/${selectedFacilityId}/site-visit-report?${siteVisitQueryParams?.toString()}`, { replace: true })
        }
        else if( (urlParams.toString()) ){
            const queryParams = Object.fromEntries(urlParams.entries());
            if (queryParams.page && Number(queryParams.page) > 1) {
                queryParams.page = '1'; // Update the page to 1
            }
            const queryString = new URLSearchParams(queryParams).toString();            
            navigate(`/client/${client_id}/digitalisation/${selectedFacilityId}/${tabId}?${queryString}`,{ replace: true });
        }
        else {
            navigate(`/client/${client_id}/digitalisation/${selectedFacilityId}/${tabId}`, { replace: true });
        }
    }, [selectedFacilityId]);

    const noProjectMsg = (<h4 style={{ textAlign: 'center' , padding: '26px'}}>No projects found</h4>)

    return <>
        <div className='w3-padding-large'>
            <div >
                <Grid item style={{ paddingBottom: '20px' }} className='hide-in-print'>
                    <FormControl size='small'>
                        <InputLabel id="log-select-label">Select Facility</InputLabel>
                        <Select
                            fullWidth
                            labelId="facility"
                            value={facilities.find(facility => facility.id === facilityId)?.id || ''}
                            label="Select Facility"
                            name="facility"
                            onChange={e => { handleFacilityChange(e) }}
                        >
                            {facilities.map(item => <MenuItem key={`facility_${item.id}`} value={item.id}> {item.facility_name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box id="digital-twin-tab-container" sx={{ borderBottom: 1, borderColor: 'divider',  display: 'flex', alignItems: 'center' }}>
                            <TabList onChange={handleTabSwitch} aria-label="lab API tabs example">
                                <Tab label="Live View" value="live" />
                                <Tab label="Summary Table" value="summary-table" />
                                <Tab label="Reports" value="report-view" />
                                <Tab label="Water Balance" value="water-balance" />
                                <Tab label="Tickets" value="tickets" />
                                <Tab label="Site Visit" value="site-visit-report" />
                                <Tab label="Calibration Report" value="calibration-report" />
                                
                            </TabList>
                        </Box>
                        <TabPanel style={{ padding: '0px' }} value="live">
                        <Can I="view" a="Live">
                                {(() => {
                                    if (showLoader || (projectFacility.isFetching) || (allFacilities.isFetching)) {
                                        return (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '26px' }}>
                                                <CircularProgress />
                                            </Box>
                                        );
                                    } else if (projectsFetched && !showLoader && ( pids?.length === 0 || facilities?.length === 0)) {
                                        return noProjectMsg;
                                    } else if ( ( pids?.length > 0 && facilities?.length > 0) ) {
                                        return pids?.map((pid, index) => (
                                            <LiveView key={`live_view_pid_${pid}_${index}`} dPid={pid} digitalisationView={true} />
                                        ));
                                    }
                                })()}
                            </Can>
                        </TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="summary-table">
                            <Can I="view" a="Assets">
                                {projectsFetched && (pids?.length === 0 || facilities?.length === 0) ? (
                                    noProjectMsg
                                ) : (
                                    pids?.map(pid => (
                                        <AssetsDetails key={pid} dPid={pid} />
                                    ))
                                )}
                            </Can>
                        </TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="report-view">
                            <Can I="view" a="ReportView">
                                {projectsFetched && (pids?.length === 0 || facilities?.length === 0) ? (
                                    noProjectMsg
                                ) : (
                                    <ReportsView projectIds={pids} facility_id={selectedFacilityId} digiView={true} />
                                )}
                            </Can>
                        </TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="water-balance">
                            <Can I="view" a="WaterBalanceReport">
                                <WaterBalance />
                            </Can>
                        </TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="tickets">
                            <Can I="view" a="Tickets">
                                <Tickets />
                            </Can>
                        </TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="site-visit-report">
                            <Can I="view" a="SiteVisitReport">
                                { projectsFetched && (pids?.length === 0 || facilities?.length === 0) ? (
                                    noProjectMsg
                                    ) : ( <SiteVisitReportList dPids={pids}  />)
                                }
                            </Can>
                        </TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="calibration-report">
                            <Can I="view" a="DigiCalibrationReport">
                                { projectsFetched && (pids?.length === 0 || facilities?.length === 0) ? (
                                    noProjectMsg
                                    ) : ( <CalibrationList key={facilityId} dFacilityId={facilityId} dPids={pids} />)
                                }
                            </Can>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
        </div>
    </>
}