import { CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import SupersetGraph from './SupersetGraph';
import HomeDashboard from './HomeDashboard';
import SensorHealth from '../Dashboard/SensorHealth';

export default function SupersetDashboard(props) {

    const { dashboardType, dashboardList = [], client_id = null, facility_ids = [], projectList = [],
        dashboardHeight, showDashboard, loadDashboard } = props;
    const [showLoader, setShowLoader] = useState(true);
    const [supersetResourceList, setSupersetResourceList] = useState([]);
    const [supersetGuestToken, setSupersetGuestToken] = useState(null);
    const [homeDashboardData, setHomeDashboardData] = useState({});

    useEffect(() => {
        if (dashboardType !== 'Home') {
            return
        }
        const params = {
            pids: projectList,
        }
        axios.get('/papi/v1/home-dashboard/', { params })
            .then((response) => {
                setShowLoader(false);
                if (response?.data) {
                    setHomeDashboardData(response?.data)
                }
            })
            .catch((error) => {
                setShowLoader(false);
                console.error(`Error fetching ${dashboardType} dashboard:`, error);
            });
    }, [1])

    useEffect(() => {
        if ((dashboardType === 'Home') || (!loadDashboard && !showDashboard && dashboardType === 'ESG' && (facility_ids.length === 0 || dashboardList.length === 0 || projectList.length === 0 || showLoader))) {
            return;
        }
        setShowLoader(true);
        const params = {
            section: dashboardType,
            type: 'Dashboard',
            client_id: dashboardType !== 'Home' ? client_id : null,
            facility_ids,
            dashboard_ids: dashboardList,
            pids: projectList,
        }

        axios.post('/api/v1/report/superset-embedd', params).then((resp) => {
            setShowLoader(false);
            const response = resp?.data?.data;
            if (!response?.guestToken) {
                return;
            }
            setSupersetGuestToken(response?.guestToken);
            setSupersetResourceList(response.resource_list);
        }).catch(ex => {
            setShowLoader(false);
            console.error(ex);
        });
    }, [showDashboard, loadDashboard]);

    if (showLoader) {
        return (
            <div className='w3-center' style={{ marginTop: 30 }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <Grid>
            {(dashboardType !== 'Home' && (
                <div style={{ display: showLoader ? 'none' : 'block' }} id="superset-container">
                    {supersetResourceList?.map((item) => (
                        <SupersetGraph
                            key={`graph-${item.id}`}
                            dashboardHeight={dashboardHeight}
                            dashboardId={item.id}
                            dashboardType={dashboardType}
                            supersetGuestToken={supersetGuestToken}
                        />
                    ))}
                </div>
            ))}

            {dashboardType === 'Home' && (
                <>
                    <HomeDashboard
                        homeDashboardData={homeDashboardData}
                        projectList={projectList}
                    />
                    <SensorHealth
                        clientId={client_id}
                        projectList={projectList}
                    />
                </>
            )}
        </Grid>
    )
}