import { Button, CircularProgress, Dialog, IconButton, DialogActions, Grid } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { getInsightKey, getMeterByMeterId, getOpenaiResponseForImageUrl, getUserProjects } from '../Redux/actions';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { cleanSvgIds, insertDirectDisplayValues, insertTankValues, showLastUpdatedTime, showWaterFlow } from './Common/RTMLineDiagram';
import { Lightbulb } from '@mui/icons-material';
import * as htmlToImage from 'html-to-image';
import InsightsModal from './Common/InsightsModal';
import { generateInsightKey } from '../utils';
import TrendingAnalysis from './Meters/TrendingAnalysis';
import CloseIcon from '@mui/icons-material/Close';

export default function LiveView({ dPid, digitalisationView = false }) {
    const dispatch = useDispatch();
    const { client_id, id } = useParams();
    const pid = id ? id : dPid
    const state = useSelector(st => st);
    const { userProjectsList } = state
    const [userProjects, setUserProjects] = useState([])
    const [htmlContent, setHtmlContent] = useState('');
    const [showLoader, setShowLoader] = useState(true);
    const [insightLoader, setInsightLoader] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const regex = /[!@#$%^&*(),.?":{}|<>]/;
    const [openModal, setOpenModal] = useState(false);
    const [insights, setInsights] = useState([]);
    const [result, setResult] = useState(false);
    const [showInsightButton, setShowInsightButton] = useState(true)
    const liveViewRef = useRef(null);
    const [insightKey, setInsightKey] = useState(null)
    const [selectedLiveViewRef, setSelectedLiveViewRef] = useState(null);
    const [meterId, setMeterId] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [projectFound, setProjectFound] = useState(true);
    const [permissionError, setPermissionError] = useState(false);
    const [insightData, setInsightData] = useState([]);
    const [pidNotFound, setPidNotFound] = useState(false);



    let navigate = useNavigate()

    const meterIndicators = [
        'sensor-value',
        'sensor-last-updated-time',
        'tank-value-percentage',
        'tank-value-quantity',
        'tanker-rect',
        'flow-indicator'
    ]

    useEffect(() => {
        const imageDiv = document?.getElementById(`pid-${pid}`)
        if (!imageDiv) {
            return
        }
        imageDiv.addEventListener('mouseover', handleMouseOver);
        imageDiv.addEventListener('click', handleClick);
    
        return () => {
            imageDiv.removeEventListener('click', handleClick);
            imageDiv.removeEventListener('mouseover', handleMouseOver);
        };
    }, []);

    const handleMouseOver = (event) => {
        const hoveredElementId = event.target.id;

        // Check if hoveredElementId starts with any value in meterIndicators
        const startsWithMeterIndicator = meterIndicators.some(indicator => hoveredElementId.startsWith(indicator));
    
        if (startsWithMeterIndicator) {
            const splitedElements = hoveredElementId.split('-');
            const lastWord = splitedElements[splitedElements.length - 1];
            if (!regex.test(lastWord)) {
                event.target.style.cursor = 'pointer';
            }
        }
    };

    const handleClick = (event) => {
        if(event?.target?.style?.cursor === 'pointer') {
            const clickedElementId = event.target.id;
            const splitedElements = clickedElementId.split('-');
            let meterId = splitedElements[splitedElements.length - 1];

            // Check if clickedElementId matches any of the patterns `${indicator}-${meterId}`
            const isMeterIndicator = meterIndicators.some(indicator => clickedElementId === `${indicator}-${meterId}`);

            if (isMeterIndicator && !regex.test(meterId)) {
                meterId = meterId.split('_')[0];
                dispatch(getMeterByMeterId(meterId, pid)).then((res) => {
                    const mid = res?.data?.data?.mid
                    setMeterId(mid); 
                    setIsDialogOpen(true); 
                })
            } 
        }
    };

    const handleViewTitle = () => {
        const currentProject = userProjects?.find((item) => item.card_id === Number(pid))
        const projectName = currentProject?.card_value_main?.replace("WA-", "")?.trim();
        return `Live View ${projectName ? `- ${projectName}` : ''}`
    }

    const handleCloseModal = () => {
        setIsDialogOpen(false);
        setMeterId(null); 
    };

    useEffect(() => {
        setShowLoader(true)
        dispatch(getUserProjects(client_id)).then((res) => {
            if (res && res.status === 200 && res?.data?.data) {
                const filteredProjects = res.data.data
                // .filter(project => project.projname.startsWith('WA-'));

                const newKpisData = filteredProjects?.map((project) => {
                    const val = Math.floor(Math.random() * 100) + 1;
                    return {
                        address: project.address,
                        region: project.region,
                        capacity: project.plantcapacity,
                        segment: project.segment,
                        type: project.planttype,
                        card_value_main: project.projname, //project.projname.substring(4)
                        card_value_sec: `${val} %`,
                        card_growth: val > 50 ? 'positive' : 'negative',
                        card_id: project.pid,
                    };
                });
                if (newKpisData?.length > 0) {
                    setUserProjects(newKpisData)
                    setShowLoader(false)
                }
            }
        });

    }, [1])

    useEffect(() => {
        if (pid) {
            handleLiveView(pid);
            const pollInterval = setInterval(() => {
                handleLiveView(pid);
            }, 120000);

            return () => {
                clearInterval(pollInterval);
            };
        } else {
            setProjectFound(false)
        }
    }, [pid]);

    useEffect(() => {
        handleViewTitle()
    },[userProjects])

    const handleLiveView = (pid) => {
        if (!pid) {
            return
        }
        if (isNaN(pid) || /[!@#$%^&*(),.?":{}|<>]/g.test(pid.toString()) || /\D/.test(pid.toString())) {
            setPidNotFound(true)
        }
        const params = {
            pid,
            client_id
        }
        setShowLoader(true);
        axios.get(`/api/v1/project/get-line-diagram/`, { params })
            .then((response) => {
                if (!response.data.success) {
                    setErrorMessage(response.data.message)
                    if(('authorized' in response.data) && (!response.data?.authorized)) {
                        setPermissionError(true)
                    } else {
                        setPermissionError(false)
                    }
                }
                setShowLoader(false);
                const fileContent = response?.data?.file_content;
                if (fileContent) {
                    setResult(true)
                    let svg = fileContent.replaceAll('.st', `.pid-${pid} .st`);
                    svg = svg.replaceAll('SVGID_', `SVGID_${pid}_`);
                    setHtmlContent(svg)
                    drawRTMLineDiagram(pid, svg)
                } else {
                    setResult(false)
                    setHtmlContent('<div style="text-align: left;font-size: 16px;">Not available</div>')
                }
            })
            .catch((error) => {
                setShowLoader(false);
                console.error('Error fetching HTML:', error);
            });
    }

    const drawRTMLineDiagram = (pid, svgFileContent) => {
        const params = {
            'pids': [pid],
            client_id,
            'pid': pid
        }
        axios.get(`/papi/v1/get-meters-live-data/`, { params }).then((response) => {
            const parameterData = response?.data?.parameters;
            $('.go-live-container').empty()
            // $('.project-live-view-chart').html(fileContent)
            // $(`#project_live_view_chart_${pid}`).html(svgFileContent)
            if (parameterData) {
                parameterData.forEach(item => {
                    cleanSvgIds(pid);
                    insertDirectDisplayValues(item, pid);
                    insertTankValues(item, pid);
                    showWaterFlow(item, pid);
                    showLastUpdatedTime(item, pid);
                });
            }
        }).catch((error) => {
            console.error('Error fetching HTML:', error);
        });

    }

    if (userProjectsList?.isFetching) {
        return <div className='w3-center' style={{ marginTop: 30 }}>
            <CircularProgress />
        </div>
    }

    if (errorMessage) {
        return (
            <div>
                {!permissionError && <h5 className="card-title vertical-center w3-padding-large" style={{ lineHeight: '1.5' }}>
                    {handleViewTitle()}
                </h5>}
                <Grid container >
                    <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: permissionError ? 'center' : 'left', height: permissionError ? '500px' : '0px' , alignItems: 'center' }}>
                        <h5 className='w3-text-grey' style={{ paddingLeft: permissionError ? '0px' : '8px', fontSize: permissionError ? '18px' : '16px'}}>
                            {errorMessage}
                        </h5>
                    </Grid>
                </Grid>
            </div>
        )
    }

    if(pidNotFound) {
        return(<Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px',  alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </Grid>
        </Grid>
            );
    }

    const prepareInsightModal = () => {
        const key = generateInsightKey( `pid-${pid}`, client_id, 'live-view')
        dispatch(getInsightKey(key)).then((res) => {
            setOpenModal(true);
            setShowInsightButton(false)
            if( res.data.success ){
                setInsightData(res.data)
                return
            }
            setSelectedLiveViewRef(liveViewRef.current)
            setInsightKey(key)
        })
    }

    const resetInsightData = () => {
        setInsightData([]); 
      };

    return (
        <div className="main w3-padding-large" id={`pid-${pid}`}>
            <div ref={(el) => (liveViewRef.current = el)}>
                <div className="row">
                    <div className="col-md-6">
                        <h5 className="card-title vertical-center" style={{ lineHeight: '1.5' }}>
                            {handleViewTitle()}
                        </h5>
                    </div>
                    {((!showLoader && !userProjectsList?.isFetching && userProjects && userProjects.length === 0 && !digitalisationView) || (!projectFound && !digitalisationView)) ? (
                        <div className='no-data-msg'>No Projects Available</div>
                    ) : (
                        <>
                            <div className="col-md-6" style={{
                                display: 'flex', justifyContent: 'flex-end',
                                margin: '10px 0px', alignItems: 'center'
                            }}>
                                {/* <Select
                                    value={selectedProject}
                                    name="project"
                                    required
                                    fullWidth
                                    onChange={(e) => handleSelect(e)}
                                >
                                    {userProjects?.map(item => (
                                        <MenuItem key={item?.card_id} value={item?.card_id}>
                                            {item?.card_value_main}
                                        </MenuItem>
                                    ))}
                                </Select> */}
                                {(result && showInsightButton)  && (<div>
                                <Button
                                    id="live-insight"
                                    size='small'
                                    startIcon={<Lightbulb color='info' />}
                                    variant='outlined'
                                    onClick={() => {
                                        prepareInsightModal()}}
                                    >
                                    View Insights
                                </Button>
                            </div>)}
                            </div>
                            {/* <div className="col-md-2" style={{ padding: '10px 0 15px 0' }}>
                                <button style={{ backgroundColor: '#53be04 !important' }}
                                    onClick={() => handleLiveView(selectedProject)}
                                    className="btn btn-success"
                                    id="generate-live-view"
                                >
                                    Generate Live View
                                </button>
                            </div> */}
                        </>
                    )}
                </div>
                {(userProjects && userProjects?.length > 0) && (
                    <div style={{ padding: '0 20px 20px 20px', marginTop: 20 }}>
                        <div className="row">
                            <div id={`project_live_view_chart_${pid}`} className="col-md-12 project-live-view-chart" style={{ height: '70vh' }}>
                                {showLoader ?
                                    <div className='w3-center' style={{ marginTop: 30 }}>
                                        <CircularProgress />
                                    </div> : (
                                        // <div ref={liveViewRef}>
                                            <div id={`${pid}_live_view_wrapper`} className='live-view'>
                                                {htmlContent && (
                                                    <div
                                                        className={`svg-cls pid-${pid}`}
                                                        id={`${pid}_live_view_content_id`}
                                                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                                                    />
                                                )}
                                            </div>
                                        // </div>
                                    )}
                            </div>
                        </div>
                    </div>
                )}
                {/* Use the InsightsModal component */}
                <InsightsModal
                    openInsight={openModal}
                    closeInsight={()=> setOpenModal(false)}
                    setShowInsightButton={() => setShowInsightButton(true)}
                    domRef={selectedLiveViewRef}
                    insightKey={insightKey}
                    liveViewInsights={insightData}
                    resetLiveViewInsights={resetInsightData}
                />
                {/* Dialog for TrendingAnalysis */}
                <Dialog fullWidth open={isDialogOpen} onClose={handleCloseModal} maxWidth="lg">
                    <DialogActions>
                        <IconButton onClick={handleCloseModal} color="primary">
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                    <TrendingAnalysis meterId={meterId} />
                </Dialog>
            </div>
        </div>
    )
}