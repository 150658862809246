import { Button, CircularProgress, Grid, keyframes } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getMeterByMeterId, getProjectById, getWeeklySummaryTabs } from '../Redux/actions';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import TrendingAnalysisChart from './TrendingAnalysisChart';
import { Lightbulb } from '@mui/icons-material';
import { meterGroupDisplayOrder } from '../utils';
import * as htmlToImage from 'html-to-image';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import InsightsModal from './Common/InsightsModal';
import { generateInsightKey } from '../utils';

export default function AssetsDetails({dPid}) {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { client_id } = useParams();
    const [projectData, setProjectData] = useState({});
    const [summaryTabs, setSummaryTabs] = useState([]);
    const [previousDaysTab, setPreviousDaysTab] = useState(30);
    const [htmlContent, setHtmlContent] = useState('');
    const [activeSummaryTab, setActiveSummaryTab] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [chartSummaryPoints, setChartSummaryPoints] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [insightLoader, setInsightLoader] = useState(true);
    const [showSummaryTabLoader, setShowSummaryTabLoader] = useState(true);
    const [currentMeterId, setCurrentMeterId] = useState('');
    const [currentParameter, setCurrentParameter] = useState('');
    const [meterProps, setMeterProps] = useState({});
    const [hasSetCurrentMeterId, setHasSetCurrentMeterId] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [insights, setInsights] = useState([]);
    const [result, setResult] = useState(false);
    const [showSummaryInsightButton, setShowSummaryInsightButton] = useState(true)
    const [showTrAnlysInsightButton, setShowTrAnlysInsightButton] = useState(true)
    const [currentRef, setCurrentRef] = useState(null)
    const [refId, setRefId] = useState(null)
    const [insightKey, setInsightKey] = useState(null)
    const [endDateOfTrndAnalys, setEndDateOfTrndAnalys] = useState(null)
    const [startDateOfTrndAnalys, setStartDateOfTrndAnalys] = useState(null)
    const pid = id ? id : dPid
    const [pidNotFound, setPidNotFound] = useState(false);

    const [chartType, setChartType] = useState('bar');
    const chartTypesList = [
        {label: 'bar', icon: BarChartIcon}, 
        {label: 'line', icon: ShowChartIcon}
    ];
    const summaryTableRef = useRef(null);
    const trendingAnalysisRef = useRef(null);
    const config_meter_group = {
        'water_quality': { 'displayName': 'Water Quality' },
        'water_quantity': { 'displayName': 'Water Quantity' },
        'energy': { 'displayName': 'Energy' },
        'equipment_status': { 'displayName': 'Equipment Status' },
        'level': { 'displayName': 'Tank Level' },
        'pressure': { 'displayName': 'Pressure' },
        'temperature': { 'displayName': 'Temperature' },
        'air_quality': { 'displayName': 'Air Quality' }
    }

    const nPreviousDays = [
        { label: 'Today', value: 0 },
        { label: 'Previous Day', value: 1 },
        { label: '7 - Days', value: 7 },
        { label: '30 - Days', value: 30 },
        { label: '90 - Days', value: 90 },
    ]

    useEffect(() => {
        if (isNaN(pid) || /[!@#$%^&*(),.?":{}|<>]/g.test(pid.toString()) || /\D/.test(pid.toString())) {
            setPidNotFound(true)
        }
        if (pid) {
            dispatch(getProjectById(client_id, pid)).then((resp) => {
                const response = resp.data.projectData
                if (!response) {
                    return;
                }
                setProjectData(response);
            });
            dispatch(getWeeklySummaryTabs(client_id, pid)).then((tabs) => {
                if (tabs.data.success) {
                    const tabData = tabs?.data?.data;
                    const tabList = meterGroupDisplayOrder?.filter(tab => tabData?.includes(tab));
                    setSummaryTabs(tabList)
                    setActiveSummaryTab(tabList[0])
                } else {
                    setShowLoader(false);
                    setShowSummaryTabLoader(false);
                    setHtmlContent('<div style="margin:12px 0 20px 0; font-size: 16px">Not available</div>')
                }
            });
        }
    }, [1]);

    useEffect(() => {
        if (!activeSummaryTab) {
            return;
        }
        setShowLoader(true);
        setChartType('bar')
        setCurrentMeterId('')
        setMeterProps({});
        setPreviousDaysTab(30);
        handleSelectSummaryTab(activeSummaryTab);
    }, [activeSummaryTab]);

    useEffect(() => {
        if (!currentMeterId || !pid) {
            return
        }
        dispatch(getMeterByMeterId(currentMeterId, pid)).then((resp) => {
            const meterProp = resp?.data?.data
            if (!meterProp) {
                return;
            }
            setMeterProps(meterProp);
            handleSelectPreviousDaysTab(meterProp.meter_group, meterProp.metername, currentMeterId, previousDaysTab)
        })
    }, [currentMeterId])

    useEffect(() => {
        const wrapperIdEle = document.getElementById(`summary_table_parameter_row_id_${pid}`)
        const divElements = wrapperIdEle?.getElementsByClassName('summary-table-parameter-row');
        if (!divElements) {
            return
        }
        const firstElement = divElements && divElements[0];
        if (!hasSetCurrentMeterId && firstElement) {
            const rowId = firstElement?.getAttribute('data-row-id');
            setCurrentMeterId(rowId)
            setHasSetCurrentMeterId(true);
        }
        for (const divElement of divElements) {
            divElement.addEventListener('click', handleClickSummaryTableParameter);
        }

        return () => {
            for (const divElement of divElements) {
                divElement.removeEventListener('click', handleClickSummaryTableParameter);
            }
        };
    }, [htmlContent]);

    const handleClickSummaryTableParameter = (e) => {
        const clickedElement = e.target;
        setPreviousDaysTab(30);
        setShowLoader(true)
        // setChartData([]);
        // setChartSummaryPoints([]);
        const parameter = clickedElement.getAttribute('data-parameter-name');
        const rowId = clickedElement.getAttribute('data-row-id');
        const meterGroup = clickedElement.getAttribute('data-meter-group');
        setCurrentMeterId(rowId)
        setCurrentParameter(parameter);
        handleSelectPreviousDaysTab(meterGroup, parameter, rowId, previousDaysTab)
    }

    const handleSelectSummaryTab = async (tab) => {
        setShowSummaryTabLoader(true)
        const params = {
            'pid': pid,
            'meter_group': tab,
            "client_id": client_id
        }
        await axios.get(`/papi/v1/get_weekly_summary_table_data/`, { params })
            .then((response) => {
                if (response) {
                    setResult(true)
                    setHtmlContent(response.data);
                }
            })
            .catch((error) => {
                setResult(false)
                setShowLoader(false)
                setChartData([])
                setHtmlContent('<div style="margin:12px 0 20px 0; font-size: 16px">Not available</div>')
                console.error('Error fetching HTML:', error);
            });
        setHasSetCurrentMeterId(false);
        setShowSummaryTabLoader(false);
    }

    const handleSelectPreviousDaysTab = async (meter_group, parameter_name, parameter_id, previousDays) => {
        setShowLoader(true);
        const svg_height = 108
        const svg_width = 1253
        const parameter = meter_group == 'water_balance' ? parameter_name : parameter_id
        setCurrentParameter(parameter);
        const params = {
            "pid": pid,
            'parameter': parameter,
            'meter_group': meter_group,
            'nprevious_days': previousDays,
            'svg_height': svg_height,
            'svg_width': svg_width,
            "client_id": client_id
        }
        await axios.get(`/papi/v1/get_parameter_breakup_data/`, { params })
            .then((response) => {
                if (!response) {
                    return
                }

                let data = response.data;
                if (typeof data !== 'object') {
                    try {
                        data = data.replace(/NaN/g, 'null');
                        data = JSON.parse(data);
                    } catch (error) {
                        console.error('Error parsing parameter breakup response data:', error);
                        return;
                    }
                }
                setShowLoader(false);
                setChartData(data.table_data_list)
                setChartSummaryPoints(data.chart_summary_points);
            })
            .catch((error) => {
                setShowLoader(false);
                console.error('Error fetching HTML:', error);
            });
    }

    const loadModalTitle = () => {
        const now = new Date();
        const dateFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const timeFormatOptions = { hour: '2-digit', minute: '2-digit' };

        const formatDate = now.toLocaleDateString(undefined, dateFormatOptions);
        const formatTime = now.toLocaleTimeString(undefined, timeFormatOptions);

        const formattedDate = `${formatDate} From 00:00 - To ${formatTime}`;
        return `Live Monitoring - ${projectData.projname?.replace("WA-", "").trim()} - (${formattedDate})`
    }

    const handleShowTrendingAnalysis = () => {
        if (showLoader) {
            return <div className='w3-center' style={{ marginTop: 20 }}><CircularProgress /></div>
        } else if (chartData?.length > 0) {
            return <TrendingAnalysisChart tableData={chartData} chartType={chartType}
                svgHeight={114} svgWidth={1253} npreviousDays={previousDaysTab} meterProps={meterProps} chartSummaryPoints={chartSummaryPoints}
            />
        } else if (chartData?.length == 0 || summaryTabs?.length == 0) {
            return <div className='no-data-msg'>No Data Found</div>
        }
    }

    const prepareInsightModal = (ref, currentRefId) => {
        setEndDateOfTrndAnalys(null)
        setStartDateOfTrndAnalys(null)
        setOpenModal(true);
        setCurrentRef(ref)
        setRefId(currentRefId)

        if ( currentRefId === 'trendingAnalysisRef') {
            setEndDateOfTrndAnalys(chartData[chartData?.length - 1]?.Date)
            setStartDateOfTrndAnalys(chartData[0]?.Date)
        }
    }

    const handleShowTitle = () => {
        const parameterName = meterProps.metername
        let title = parameterName;
        if (meterProps?.meter_group == 'level' && /,\s?KL(D)?$/i.test(parameterName)) {
            title = parameterName.replace(/,\s?KL(D)?$/, ', Meters');
        }
        return `Trending Analysis ${title ? `- ${title}` : ''}`
    }

    if (pidNotFound) {
        return (<Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px',  alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </Grid>
        </Grid>
        );
    }

    return (
        <div className="main w3-padding-large" >
            <div ref={summaryTableRef}>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body weekly-summary-table">
                            <div className='w3-row'>
                                <div className="w3-col m5 s12">
                                    <h5 className="card-title">Summary - {projectData.projname?.replace("WA-", "").trim()}</h5>
                                </div>
                                <div className="w3-col m7 s12 float-end text-end" style={{ marginTop: 20 }}>
                                    {summaryTabs?.map((item) => (
                                        <button className="btn btn-secondary tabs-meter-group btn-sm"
                                            style={{
                                                backgroundColor: activeSummaryTab === item ? '#215ED7' : '#6c757d',
                                                textTransform: 'capitalize',
                                                margin: 2
                                            }}
                                            onClick={() => {
                                                setActiveSummaryTab(item)
                                            }}
                                        >
                                            {config_meter_group[item]?.displayName}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            {(result && showSummaryInsightButton) && (
                            <div style={{ margin: '10px 0px', display:'flex', justifyContent: 'end'}}>
                                <Button
                                    id="summary-insight"
                                    size='small'
                                    startIcon={<Lightbulb color='info' />}
                                    variant='outlined'
                                    onClick={() => {
                                        setShowSummaryInsightButton(false);
                                        setTimeout(() => {
                                            prepareInsightModal(summaryTableRef, 'summaryTableRef');
                                            const key = generateInsightKey(`pid-${pid}`, activeSummaryTab, client_id, 'summary-tbl');
                                            setInsightKey(key)
                                        }, 500);
                                    }}
                                >
                                    View Insights
                                </Button>
                            </div>
                            )}
                            {showSummaryTabLoader ? (
                                <div className='w3-center' style={{ marginTop: 30 }}>
                                    <CircularProgress />
                                </div>
                            ) :
                                <div id={`summary_table_parameter_row_id_${pid}`} className='card-content table-responsive w3-margin-top' style={{maxHeight: 400}} dangerouslySetInnerHTML={{ __html: htmlContent }} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div ref={trendingAnalysisRef}>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body row" style={{ overflow: 'hidden' }}>
                            <div className="col-md-6">
                                <h5 className="card-title meter-trend-barchart">
                                    {handleShowTitle()}
                                </h5>
                            </div>
                            <div className="col-md-6 float-end text-end" style={{ marginTop: 20 }}>
                                {nPreviousDays?.map((item) => (
                                    <button
                                        style={{
                                            backgroundColor: previousDaysTab === item.value ? '#215ED7' : '#6c757d',
                                            color: '#fff',
                                            marginRight: 4
                                        }}
                                        className="btn btn-secondary btn-sm tabs-nprevious-days"
                                        onClick={(e) => {
                                            if (item.value != previousDaysTab) {
                                                setPreviousDaysTab(item.value);
                                                handleSelectPreviousDaysTab(activeSummaryTab, currentParameter, currentMeterId, item.value)
                                            }
                                        }}>
                                        {item.label}
                                    </button>
                                ))}
                            </div>
                            {(chartData?.length > 0 && showTrAnlysInsightButton) && (
                                <div style={{ margin: '10px 0px', display: 'flex', justifyContent: 'end' }}>
                                    {chartTypesList?.map((item) => (
                                        <Button
                                            id={`${item.label}-chart`}
                                            className='w3-margin-right'
                                            size='small'
                                            variant={`${chartType === item.label ? 'contained' : 'outlined'}`}
                                            onClick={() => {
                                                setChartType(item.label)
                                            }}
                                        >
                                        <item.icon color={`${chartType === item.label ? '#fff' : 'info'}`} />
                                    </Button>
                                    ))}
                                     <Button
                                        id="trending-analysis-insight"
                                        size='small'
                                        startIcon={<Lightbulb color='info' />}
                                        variant='outlined'
                                        onClick={() => {
                                            setShowTrAnlysInsightButton(false);
                                            setTimeout(() => {
                                                prepareInsightModal(trendingAnalysisRef, 'trendingAnalysisRef');
                                                const key = generateInsightKey(`pid-${pid}`, `meterid-${currentParameter}`, meterProps?.meter_group, previousDaysTab, client_id, 'parameter-breakup');
                                                setInsightKey(key)
                                            }, 500);
                                        }}
                                    >
                                        View Insights
                                    </Button>
                                </div>
                            )}
                            <div className="card-content col-md-12"
                                // style={{ height: '25vh' }} 
                                id="chart-trend-breakup">
                                {handleShowTrendingAnalysis()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Use the InsightsModal component */}
            <InsightsModal
                domRef={currentRef}
                openInsight={openModal}
                closeInsight={()=> setOpenModal(false)}
                setShowInsightButton={() => {
                    if (refId === 'summaryTableRef') {
                        setShowSummaryInsightButton(true);
                    } else {
                        setShowTrAnlysInsightButton(true);
                    }
                }}
                insightKey={insightKey} 
                endDateOfTrndAnalys={endDateOfTrndAnalys} 
                startDateOfTrndAnalys={startDateOfTrndAnalys} 
            />
            {/* Modal */}
            <div style={{ display: !showModal ? 'none' : '' }} className="modal fade" id="go-live-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {loadModalTitle()}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body project-live-view-chart">
                            {!modalContent ? (
                                <div className='w3-center' style={{ marginTop: 30 }}>
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                            )}
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => {
                                setShowModal(false)
                                setModalContent('')
                            }} type="button" className="btn btn-secondary close-line-diagram" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}