import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectById } from '../../Redux/actions';
import { isGreenUser, showCircularProgress } from '../../utils/Common';

export default function DayWiseReport(
    { fromDate, toDate, projectName, daywiseReportData, client_id, pid, digiView }
) {
    const [projectData, setProjectData] = useState([])
    const dispatch = useDispatch();
    const state = useSelector(st => st);
    const { currentUser: { uniqueId } } = state;
    const greenUser = isGreenUser(uniqueId);
    useEffect(() => {
        if (digiView) {
            loadProjectData();
        }
    }, [1])

    const loadProjectData = async () => {
        const projectResponse = await dispatch(getProjectById(client_id, pid));
        if (projectResponse?.data?.success && projectResponse?.data?.projectData) {
            setProjectData(projectResponse.data.projectData);
        }
    }

    const renderTableData = (data, meterGroup) => (
        data?.length > 0 && (
            <TableData
                data={data}
                meterGroup={meterGroup}
                greenUser={greenUser}
            />
        )
    );
    if (digiView && (!projectData || projectData?.length === 0)) {
        return showCircularProgress
    }

    return (
        <div className="reports-tables-container" style={{ marginTop: '50px' }}>
            <table className="table-header day-wise-report-table table-bordered monthly-report-table">
                <tbody>
                    <tr className="table-header-light-bg">
                        <th style={{ fontWeight: 'bold' }}>
                            {digiView ? projectData?.projname : projectName}
                        </th>
                    </tr>
                    <tr className="table-header-light-bg">
                        <th style={{ fontWeight: 'bold' }}>
                            From: {moment(fromDate).format('YYYY-MM-DD')} 00:00 AM - To: {moment(toDate).format('YYYY-MM-DD')} 11:59 PM
                        </th>
                    </tr>
                    <tr className="table-header-light-bg">
                        <th
                            contentEditable={greenUser}
                            suppressContentEditableWarning={true}
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                        >
                            Real Time Monitoring (RTM) - Day-wise Report - Powered by{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.greenvironmentindia.com/">
                                Greenvironment India
                            </a>
                        </th>
                    </tr>
                </tbody>
            </table>

            {renderTableData(daywiseReportData?.qualityData, "water_quality")}
            {renderTableData(daywiseReportData?.quantityData, "water_quantity")}
            {renderTableData(daywiseReportData?.energyData, "energy")}
            {renderTableData(daywiseReportData?.pressureData, "pressure")}
            {renderTableData(daywiseReportData?.temperatureData, "temperature")}
            {renderTableData(daywiseReportData?.airQualityData, "air_quality")}
            {/* {renderTableData(daywiseReportData?.equipmentStatusData, "equipment_status")} */}
        </div>
    );
}

const TableData = ({ data, meterGroup, greenUser }) => {
    if (!data || data.length === 0) return null;

    const tableColumns = {
        common: ['Date', 'Meter Name', 'Acceptable Limits', 'Value'],
        quantityAndEnergy: ['Date', 'Meter Name', 'Initial Meter Reading', 'Final Meter Reading', 'Total Quantity'],
    };

    const tableHeader = {
        water_quality: { label: 'Water Quality', column: tableColumns.common },
        water_quantity: { label: 'Water Quantity', column: tableColumns.quantityAndEnergy },
        energy: { label: 'Energy', column: tableColumns.quantityAndEnergy },
        pressure: { label: 'Pressure', column: tableColumns.common },
        temperature: { label: 'Temperature', column: tableColumns.common },
        // equipment_status: { label: 'equipment_status', column: tableColumns.common },
        air_quality: { label: 'Air Quality', column: tableColumns.common },
    };

    // Group data by date
    const dataList = data.reduce((acc, row) => {
        acc[row.date] = acc[row.date] || [];
        acc[row.date].push(row);
        return acc;
    }, {});

    const groupedData = Object.fromEntries(
        Object.entries(dataList).sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
    );
    

    const renderRowData = (row, meterGroup) => {
        if (!tableHeader[meterGroup]) {
            return
        }
        const isWithinLimits = parseFloat(row.value) >= parseFloat(row.val_min) && parseFloat(row.value) <= parseFloat(row.val_max);

        if (meterGroup === 'water_quantity' || meterGroup === 'energy') {
            return (
                <>
                    <td contentEditable={greenUser} suppressContentEditableWarning={true} align='center'>{row.initial}</td>
                    <td contentEditable={greenUser} suppressContentEditableWarning={true} align='center'>{row.final}</td>
                    <td
                        contentEditable={greenUser}
                        suppressContentEditableWarning={true}
                        className={!row.val_min || !row.val_max ? '' : !isWithinLimits ? 'red' : 'green'}
                        align='center'
                    >{row.value}</td>
                </>
            );
        } else {
            return (
                <>
                    <td contentEditable={greenUser} suppressContentEditableWarning={true} align='center'>
                        {`${row.val_min || 'NA'} - ${row.val_max || 'NA'}`}</td>
                    <td
                        contentEditable={greenUser}
                        suppressContentEditableWarning={true}
                        className={!row.val_min || !row.val_max ? '' : !isWithinLimits ? 'red' : 'green'}
                        align='center'
                    >
                        {row.value}
                    </td>
                </>
            );
        }
    };

    return (
        <div style={{ overflowX: 'auto', width: '100%' }}>
            <table className="day-wise-report-table row-border order-column" id="quantity" style={{ width: '100%' }}>
                <thead style={{ overflowX: 'auto', width: '100%' }}>
                    <tr>
                        <th className="tg-e9yv" style={{ minWidth: '90px' }} colSpan="5" contentEditable={greenUser}>
                            {tableHeader[meterGroup]?.label}
                        </th>
                    </tr>
                    <tr>
                        {tableHeader[meterGroup]?.column.map((item, index) => (
                            <th key={index} className="tg-cqae" contentEditable={greenUser} data-name="date">
                                {item}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(groupedData).map((date) =>
                        groupedData[date].map((row, index) => (
                            <tr key={`${date}-${index}`}>
                                {index === 0 && (
                                    <td rowSpan={groupedData[date].length} className="tg-cqae">
                                        {date}
                                    </td>
                                )}
                                <td>{row.display_name}</td>
                                {renderRowData(row, meterGroup)}
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            <br />
        </div>
    );
};
