import { CircularProgress } from "@mui/material";

export const isValidDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    const regex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
    return regex.test(formattedDate);
};

export const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

export const dataNotAvailableMsg = <div style={{margin:'24px 0 20px 0', fontSize: 16}}>Data not available</div>

export const generateReportMsg = <div className='w3-center' style={{ margin: '44px 0 20px 0', fontSize: 16 }}>Please click on generate report to view the report</div>

export const showCircularProgress = (
    <div className="w3-center" style={{ marginTop: 30 }}>
        <CircularProgress />
    </div>
)

export const isGreenUser = (username) => {
    if (!username) { return }
    const mailDomain = username.trim().split('@').pop();
    return ["greenvironmentindia.com", "greenvironment.in"].includes(mailDomain)

}