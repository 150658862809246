import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClientById, getSupersetDashboards, getUserProjects } from '../../Redux/actions';
import { useParams } from 'react-router-dom';
import SupersetDashboard from './SupersetDashboard';
import { Button, Checkbox, CircularProgress, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { ETP_ENERGY_TAGS, ETP_OPEX_COST_TAGS, ETP_WATER_BALANCE_TAGS, STP_ENERGY_TAGS, STP_OPEX_COST_TAGS, STP_WATER_BALANCE_TAGS,
    STP_WQPI_TAGS, WTP_ENERGY_TAGS, WTP_WATER_BALANCE_TAGS, WTP_WQPI_TAGS } from '../../utils';

export default function ESGView() {
    const dispatch = useDispatch();
    const { client_id } = useParams();
    const state = useSelector(st => st);
    const { supersetDashboards, userProjectsList } = state
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const [clientFacilities, setClientFacilities] = useState([])
    const [dashboardList, setDashboardList] = useState([])
    const [selectedDashboards, setSelectedDashboards] = useState([])
    const [selectedProjects, setSelectedProjects] = useState([])
    const [userProjects, setUserProjects] = useState([])
    const [showDashboard, setShowDashboard] = useState(false);
    const [loadDashboard, setLoadDashboard] = useState(false);
    const [clientFound, setClientFound] = useState(true);
    const [tagTextDashboardList, setTagTextDashboardList] = useState([]);

    const dashboardTagMapping = {
        'STP Water Balance': STP_WATER_BALANCE_TAGS,
        'STP Energy Index (KW/KL)': STP_ENERGY_TAGS,
        'STP OPEX Cost Index (INR/KL)': STP_OPEX_COST_TAGS,
        'STP Water Quality Parameters Index (%)': STP_WQPI_TAGS,
        'WTP Water Balance': WTP_WATER_BALANCE_TAGS,
        'WTP Energy Index (KW/KL)': WTP_ENERGY_TAGS,
        'WTP Water Quality Parameters Index (%)': WTP_WQPI_TAGS,
        'ETP Water Balance': ETP_WATER_BALANCE_TAGS,
        'ETP Energy Index': ETP_ENERGY_TAGS,
        'ETP OPEX Cost Index': ETP_OPEX_COST_TAGS,
    };
    useEffect(() => {
        dispatch(getClientById(client_id)).then((resp) => {
            const response = resp?.data?.data
            if (!response) {
                setClientFound(false)
                return
            }
            if (response?.facilities) {
                setClientFacilities(response.facilities)
            }
        });

        dispatch(getSupersetDashboards()).then((resp) => {
            if (!resp?.data?.data || resp.data?.data?.length === 0) {
                return;
            }
            const dashboards = resp?.data?.data?.map(({ value, label }) => ({
                value,
                label: label.replace('ESG - ', '')
            }));
            setDashboardList(dashboards);
        });
        dispatch(getUserProjects(client_id));
    }, [1]);

    useEffect(() => {
        const facilityProjects = userProjectsList?.data?.data?.filter((item) => selectedFacilities.includes(item?.facilities?.id));
        const newList = facilityProjects?.reduce((acc, item) => {
            if (selectedProjects?.includes(item.pid)) {
                acc.push(item.pid);
            }
            return acc;
        }, []);
        newList && setSelectedProjects(newList)
        setUserProjects(facilityProjects);
    }, [selectedFacilities])

    useEffect(() => {
        let meterTags = new Set();
        selectedProjects?.map(pid => {
            const project = userProjects.find(proj => proj.pid === pid);
            project?.projectMeter?.forEach(item => {
                item?.meter?.tags?.forEach(meterTag => {
                    meterTags.add(meterTag.tag_text);
                });
            });
            return project?.planttype;
        });
        const uniqueMeterTags = Array.from(meterTags);
        const newDashboardList = dashboardList.filter(dashboard => {
            const tags = dashboardTagMapping[dashboard.label] || [];
            return tags.some(tag => uniqueMeterTags.includes(tag));
        });
        const filteredDashboardList = selectedDashboards.filter(value => newDashboardList.find(item => item.value === value));
        setTagTextDashboardList(newDashboardList);
        setSelectedDashboards(filteredDashboardList);
    },[selectedProjects])

    const handleDbChange = (event, name) => {
        const { target: { value } } = event;
    
        const stateMapping = {
            facility: {
                allItems: clientFacilities?.map(facility => facility.id),
                selectedItems: selectedFacilities,
                setSelected: setSelectedFacilities,
                allLabel: "Select All"
            },
            project: {
                allItems: userProjects?.map(project => project.pid),
                selectedItems: selectedProjects,
                setSelected: setSelectedProjects,
                allLabel: "Select All"
            },
            dashboard: {
                allItems: tagTextDashboardList?.map(dashboard => dashboard.value),
                selectedItems: selectedDashboards,
                setSelected: setSelectedDashboards,
                allLabel: "Select All"
            }
        };
    
        const { allItems, selectedItems, setSelected, allLabel } = stateMapping[name];
    
        if (value.includes(allLabel)) {
            if (selectedItems.length === allItems.length) {
                setSelected([]);
            } else {
                setSelected(allItems);
            }
        } else {
            const newValue = typeof value === 'string' ? value.split(',') : value;
            const filteredValue = newValue.filter(item => item !== allLabel);
            setSelected(filteredValue);
        }

        if (selectedProjects.length === 0 || selectedFacilities.length === 0 || selectedDashboards.length === 0) {
            setShowDashboard(false);
        }
    };

    const handleShowDashboard = () => {
        setShowDashboard(true);
        setLoadDashboard(!loadDashboard);
    };

    const handleClearFilter = () => {
        setSelectedFacilities([])
        setSelectedProjects([])
        setSelectedDashboards([])
    }

    const handleShowDashboardDropdown = () => {
        return <FormControl  size='small' sx={{ width: '100%' }}>
            <InputLabel id="chart">Dashboard*</InputLabel>
            <Select
                labelId="chart"
                id="chartId"
                className='white-bg'
                multiple
                disabled={selectedProjects?.length === 0 || selectedFacilities?.length === 0}
                MenuProps={{
                    autoFocus: false,
                    disableScrollLock: true,
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                    transformOrigin: { vertical: "top", horizontal: "left" },
                    getContentAnchorEl: null,
                    PaperProps: {
                        style: {
                            maxHeight: 350,
                            overflowY: "auto",
                            width: '100%',
                            maxWidth: 260
                        },
                    },
                }}
                value={selectedDashboards}
                onChange={(e) => handleDbChange(e, 'dashboard')}
                input={<OutlinedInput label="Dashboard" />}
                renderValue={(selected) => selected.length == tagTextDashboardList?.length ? 'All Dashboards' : selected.length + ' selected '}
            >
                {(selectedProjects?.length > 0 && tagTextDashboardList?.length > 0 ) && (<MenuItem style={{wordWrap: 'break-word', whiteSpace: 'normal'}} value="Select All">
                    <Checkbox checked={selectedDashboards?.length === tagTextDashboardList?.length} indeterminate={selectedDashboards?.length > 0 && selectedDashboards?.length < tagTextDashboardList?.length} />
                    <ListItemText primary="Select All" />
                </MenuItem>)}
                {selectedProjects?.length > 0 && tagTextDashboardList?.map((db) => (
                        <MenuItem style={{wordWrap: 'break-word', whiteSpace: 'normal', display: 'flex', alignItems: 'flex-start'}} key={db.value} label={db.label} value={db.value}>
                            <Checkbox style={{ paddingTop: '0px'}} checked={selectedDashboards?.includes(db.value)} />
                            <ListItemText primary={db.label} />
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    }

    if (supersetDashboards?.isFetching) {
        return (
            <div className="main w3-padding-large">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <h5 className="card-title">Sustainability Dashboard</h5>
                    </Grid>
                    <div className='w3-center' style={{ marginTop: 30 }}>
                        <CircularProgress />
                    </div>
                </Grid>
            </div>
        )
    }
    const isSubmitDisabled =  selectedFacilities?.length === 0 || selectedDashboards?.length === 0 || selectedProjects?.length === 0

    if (!clientFound) {
        return <Grid container justifyContent={"center"} style={{ marginTop: '160px' }}>
            <h5 className='w3-text-grey'>  No Data Found</h5>
        </Grid>
    }

    return (
        <div className="main w3-padding-large">
            <div>
                <Grid container spacing={2}>
                    <Grid item className='w3-margin-bottom'>
                        <h5 className="card-title">Sustainability Dashboard</h5>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='w3-margin-bottom'>
                    <Grid item md={3}>
                        <FormControl size='small' sx={{ width: '100%' }}>
                            <InputLabel id="facility">Facility*</InputLabel>
                            <Select
                                labelId="facility"
                                id="facilityId"
                                className='white-bg'
                                multiple
                                MenuProps={{
                                    autoFocus: false,
                                    disableScrollLock: true,
                                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                    transformOrigin: { vertical: "top", horizontal: "left" },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        style: {
                                            maxHeight: 350,
                                            overflowY: "auto",
                                            width: '100%',
                                            maxWidth: 260
                                        },
                                    },
                                }}
                                value={selectedFacilities}
                                onChange={(e) => handleDbChange(e, 'facility')}
                                input={<OutlinedInput label="Facility" />}
                                renderValue={(selected) => selected.length == clientFacilities?.length ? 'All Facilities' : selected.length + ' selected '}
                            >
                                {(clientFacilities?.length > 0) && (<MenuItem style={{wordWrap: 'break-word', whiteSpace: 'normal'}} value="Select All">
                                    <Checkbox checked={selectedFacilities?.length === clientFacilities?.length} indeterminate={selectedFacilities?.length > 0 && selectedFacilities?.length < clientFacilities?.length} />
                                    <ListItemText primary="Select All" />
                                </MenuItem>)}
                                {clientFacilities?.map((fac) => (
                                    <MenuItem style={{wordWrap: 'break-word', whiteSpace: 'normal', display: 'flex', alignItems: 'flex-start'}} key={fac.id} label={fac.facility_name} value={fac.id}>
                                        <Checkbox style={{ paddingTop: '0px'}} checked={selectedFacilities?.includes(fac.id)} />
                                        <ListItemText primary={fac.facility_name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={3} >
                        <FormControl size='small' sx={{ width: '100%' }}>
                            <InputLabel id="chart">Project*</InputLabel>
                            <Select
                                labelId="chart"
                                id="chartId"
                                className='white-bg'
                                multiple
                                disabled={selectedFacilities?.length === 0}
                                MenuProps={{
                                    autoFocus: false,
                                    disableScrollLock: true,
                                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                    transformOrigin: { vertical: "top", horizontal: "left" },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        style: {
                                            maxHeight: 350,
                                            overflowY: "auto",
                                            width: '100%',
                                            maxWidth: 260
                                        },
                                    },
                                }}
                                value={selectedProjects}
                                onChange={(e) => handleDbChange(e, 'project')}
                                input={<OutlinedInput label="Project" />}
                                renderValue={(selected) => selected.length == userProjects?.length ? 'All Projects' : selected.length + ' selected '}
                            >
                                {(userProjects?.filter((item) => selectedFacilities?.includes(item.facilities.id))?.length > 0 )&& (<MenuItem style={{wordWrap: 'break-word', whiteSpace: 'normal'}} value="Select All">
                                    <Checkbox checked={selectedProjects?.length === userProjects?.length} indeterminate={selectedProjects?.length > 0 && selectedProjects?.length < userProjects?.length} />
                                    <ListItemText primary="Select All" />
                                </MenuItem>)}
                                {userProjects?.filter((item) => selectedFacilities?.includes(item.facilities.id)).map((proj) => (
                                    <MenuItem style={{wordWrap: 'break-word', whiteSpace: 'normal', display: 'flex', alignItems: 'flex-start'}}  key={proj.pid} label={proj.projname} value={proj.pid}>
                                        <Checkbox style={{ paddingTop: '0px'}} checked={selectedProjects?.includes(proj.pid)} />
                                        <ListItemText primary={proj.projname.replace("WA-", "").trim()} />
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={3}>
                        {handleShowDashboardDropdown()}
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size='small'
                            style={{cursor: isSubmitDisabled ? 'not-allowed' : 'pointer', height: '98%'}}
                            onClick={handleShowDashboard}
                            disabled={isSubmitDisabled}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            size='small'
                            style={{height: '98%'}}
                            onClick={handleClearFilter}
                            disabled={!(selectedFacilities?.length > 0 || selectedProjects?.length > 0 || selectedDashboards?.length > 0)}
                        >
                            Clear
                        </Button>
                    </Grid>
                </Grid>
            </div>
            {selectedFacilities?.length === 0 && (
                <div>
                    Select a facility from the dropdown to view Sustainability Dashboard
                </div>
            )}
            {(selectedProjects?.length === 0) && (
                selectedFacilities?.length === 0 ? (
                    <div>
                        Select a facility to view the available projects
                    </div>) : (
                    <div>
                        Select a project from the dropdown to view Sustainability Dashboard
                    </div>
                )
            )}
            {(selectedDashboards?.length === 0) && (
                <div>
                    Select a dashboard from the dropdown to view Sustainability Dashboard
                </div>
            )}

            {(selectedFacilities?.length > 0 && selectedDashboards?.length > 0 && selectedProjects?.length > 0 && showDashboard) &&
                <SupersetDashboard
                    facility_ids={selectedFacilities}
                    dashboardList={selectedDashboards}
                    projectList={selectedProjects}
                    client_id={client_id}
                    dashboardType='ESG'
                    dashboardHeight="460px"
                    showDashboard={showDashboard}
                    loadDashboard={loadDashboard}
                />
            }
        </div>
    )
}